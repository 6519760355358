import { Loader } from "../utils/appLoader";
import handleError from "./errorHandler";

/**
 * This function will automatically catches all exceptions of the given function.
 * It will hide the global loader and will show the toast message for exceptions
 * @param {Function} callback
 * @returns void
 */
const safeFunction = async (
  handler,
  helperFunction = {
    catch: () => null,
    finally: () => null,
  }
) => {
  try {
    await handler();
  } catch (exception) {
    handleError(exception);
    Loader.hide();
    helperFunction.catch && helperFunction.catch();
  } finally {
    helperFunction.finally && helperFunction.finally();
  }
};

const errorWrapper = (callback, helper) => {
  const wrapperFn = async (args) => {
    await safeFunction(async () => await callback(args), helper);
  };
  return wrapperFn;
};

export default errorWrapper;
