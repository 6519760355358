import { EventEmitter } from "eventemitter3";

export const EVENT = "LOADER_EVENT";

export class AppLoader extends EventEmitter {
  show() {
    this.emit(EVENT, { visible: true });
  }
  hide() {
    this.emit(EVENT, { visible: false });
  }
}
