import {
  getAllCategories,
  getSurveyByCategory,
  addSurgeon,
  updateSurgeon,
  getSurgeonDetailsById,
  getAllSurgeons,
  deleteSurgeon,
  enableDisableSurgeon,
  addCategory,
  enableDisableCategory,
  updateCategory,
  getAllSurveys,
  deleteSurvey,
  addSurvey,
  getSurveyDetailsById,
  updateSurvey,
  getCategories,
} from "./services/admin";
import { signIn } from "./services/auth";

const API_SERVICE = {
  signIn,
  getAllCategories,
  getSurveyByCategory,
  addSurgeon,
  updateSurgeon,
  getSurgeonDetailsById,
  getAllSurgeons,
  deleteSurgeon,
  enableDisableSurgeon,
  addCategory,
  enableDisableCategory,
  updateCategory,
  getAllSurveys,
  deleteSurvey,
  addSurvey,
  getSurveyDetailsById,
  updateSurvey,
  getCategories,
};

export { API_SERVICE };
