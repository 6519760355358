// import moment from "moment";

const numbers = /[0-9]/g;
const phoneno = /^\d{10}$/;

export const validateEmail = (value) => {
  let error = false;
  if (!value) {
    error = "Email required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
};

export const validatePassword = (value) => {
  let error = false;
  if (!value) {
    error = "Password Required";
  } else if (!value.match(numbers)) {
    error = "Password should contain atleast one number";
  } else if (value.length < 6) {
    error = "Password should be minimum 6 characters";
  }
  return error;
};

export const validateName = (value, text = "") => {
  let error = false;
  if (!value) {
    error = text ? `${text} required` : "Required";
  }
  return error;
};

export const validatePhone = (value) => {
  let error = false;
  if (!value) {
    error = "Phone number is required";
  } else if (!value.match(phoneno)) {
    error = "Phone number must be exactly 10 digits";
  }
  return error;
};
