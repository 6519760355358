import { useCallback, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { adminRoot } from "../../../constants";
import { setCurrentUser } from "../../../helpers/appStorage";
import { NotificationManager } from "../../../utils/notificationManager";
import { Loader } from "../../../utils/appLoader";
import { API_SERVICE } from "../../../api";
import { errorWrapper, handleError } from "../../../helpers";
import { validateEmail, validatePassword } from "../../../validation/validator";

const useLogin = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);

  const firstRender = useRef(true);

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const onChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const validator = {
    email: validateEmail(email.trim()),
    password: validatePassword(password.trim()),
  };

  const checkFormError = () => {
    setFormError(validator);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (submitFlag) checkFormError();
  }, [email, password]);

  /**
   * This function will invoke the sign in API and verifies that the user is a valid user. Error should be handled within this function
   * @param {object} inputData
   * @returns {Promise} userData
   */
  const SignInUser = (inputData) => {
    return new Promise((resolve, _reject) => {
      const requestBody = {
        email: inputData.email,
        password: inputData.password,
      };
      API_SERVICE.signIn(requestBody)
        .then((res) => resolve(res))
        .catch((err) => {
          Loader.hide();
          if (err.message === "No login match found") return handleError("Invalid Email or Password");
          handleError(err, "Login Error");
        });
    });
  };

  const onSubmit = errorWrapper(async (event) => {
    event.preventDefault();
    setSubmitFlag(true);
    Loader.show();
    checkFormError();
    const invalidForm = Object.values(validator).some((x) => x !== false);

    if (invalidForm) {
      Loader.hide();
      return;
    }

    const reqBody = {
      email: email.trim(),
      password: password.trim(),
    };
    const userInfo = await SignInUser(reqBody);

    if (userInfo) {
      Loader.hide();
      setCurrentUser(userInfo);
      NotificationManager.success("Login successfull");
      history.push(`${adminRoot}/view-surgeons`);
    }
  });

  return { email, password, formError, onChangeEmail, onChangePassword, onSubmit };
};

export default useLogin;
