import React, { useState } from "react";
import ToastCard from "../../components/toastMessage/toastMessage";

import { CustomNotification, EVENT } from "./emitter";

const NotificationEvent = new CustomNotification();

const NotificationWrapper = ({ children }) => {
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [eventData, setEventData] = useState();

  NotificationEvent.on(EVENT, function (data) {
    setEventData(data);
    setNotificationVisible(true);
  });

  const onCancelNotification = () => {
    if (notificationVisible) {
      setNotificationVisible(false);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <ToastCard severity={eventData?.type} visible={notificationVisible} handleClose={onCancelNotification} message={eventData?.message} />
      {children}
    </div>
  );
};

const NotificationManager = NotificationEvent;

export { NotificationWrapper, NotificationManager };
