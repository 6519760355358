import React, { useState } from "react";
import LoaderView from "../../components/loader/loader";

import { AppLoader, EVENT } from "./emitter";

const LoaderEvent = new AppLoader();

const LoaderWrapper = ({ children }) => {
  const [loaderVisible, setLoaderVisible] = useState(false);

  LoaderEvent.on(EVENT, function (data) {
    setLoaderVisible(data.visible);
  });

  return (
    <div style={{ flex: 1 }}>
      <LoaderView open={loaderVisible} />
      {children}
    </div>
  );
};

const Loader = LoaderEvent;

export { LoaderWrapper, Loader };
