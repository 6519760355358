import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { errorWrapper, handleError } from "../../../helpers";
import { API_SERVICE } from "../../../api";
import { Loader } from "../../../utils/appLoader";

const useSurgeonDetails = () => {
  const location = useLocation();
  const { surgeonId } = location.state;

  const [surgeonData, setSurgeonData] = useState([]);

  const fetchSurgeonDetails = errorWrapper(async () => {
    Loader.show();
    await API_SERVICE.getSurgeonDetailsById(surgeonId).then((res) => {
      Loader.hide();
      if (res.id) {
        setSurgeonData(res);
        return;
      }
      handleError(res, "Failed to fetch surgeon details");
    });
  });

  useEffect(() => {
    if (surgeonId) fetchSurgeonDetails();
  }, [surgeonId]);

  return { surgeonData };
};

export default useSurgeonDetails;
