import React from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import Card from "../../../components/card";
import { adminRoot } from "../../../constants";
import useSurgeonDetails from "./util";

const DetailCard = ({ title, value }) => (
  <div className="detail-card-container">
    <div className="detail-tile-label">{title}</div>
    <div className="detail-tile-value">{value}</div>
  </div>
);

const SurgeonDetails = () => {
  const history = useHistory();

  const { surgeonData } = useSurgeonDetails();

  const goBack = () => history.push(`${adminRoot}/view-surgeons`);
  return (
    <div className="container-data-table">
      <Card className="p-3 table-white-card">
        <div className="position-rfh data-table">
          <div className="py-3 px-3 table-header">
            <button className="float-end card-close-icon btn-blank" onClick={goBack}>
              <AiOutlineClose size={25} color={"#000"} />
            </button>
            <div className="table-card-title">Surgeon Details</div>

            <div className="surgeon-details-content-container">
              <Row className="pt-5">
                <Col>
                  <div className="card-sub-title pb-3">Basic Details</div>
                  <DetailCard title="FIRST NAME" value={surgeonData.firstName} />
                  <DetailCard title="LAST NAME" value={surgeonData.lastName} />
                  <DetailCard title="PHONE NUMBER" value={surgeonData.phoneNumber} />
                  <DetailCard title="EMAIL" value={surgeonData.email} />
                  <DetailCard title="PASSWORD" value={surgeonData.password} />
                </Col>
                <Col>
                  <div className="card-sub-title pb-3">Assigned Surveys</div>
                  <div>
                    <ul>
                      {surgeonData?.assignedSurveys?.map((item) => (
                        <li className="detail-survey-name">{item.surveyName}</li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SurgeonDetails;
