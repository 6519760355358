import API from "../utils";
import urls from "../urls";
import { getCurrentUser } from "../../helpers/appStorage";

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const addSurgeon = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.ADD_SURGEON}/${user}`, payload);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const updateSurgeon = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.UPDATE_SURGEON}/${user}`, payload);
};

/**
 *
 * @param {string} surgeonId - Request params
 * @returns Promise
 */
export const getSurgeonDetailsById = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.GET_SURGEON_DETAILS}/${user}/${id}`);
};

/**
 *
 * @param {string} categoryId - Request params
 * @returns Promise
 */
export const getSurveyByCategory = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.GET_SURVEYBY_CATEGORY}/${user}/${id}`);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getCategories = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.GET_CATEGORY}/${user}`, payload);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getAllCategories = async () => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.GET_ALL_CATEGORIES}/${user}`);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getAllSurgeons = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.GET_ALL_SURGEONS}/${user}`, payload);
};

/**
 *
 * @param {string} surgeonId - Request params
 * @returns Promise
 */
export const deleteSurgeon = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.DELETE_SURGEON}/${user}/${id}`);
};

/**
 *
 * @param {string} surgeonId - Request params
 * @returns Promise
 */
export const enableDisableSurgeon = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.ENABLE_DISABLE_SURGEON}/${user}/${id}`);
};

/**
 *
 * @param {string} categoryId - Request params
 * @returns Promise
 */
export const enableDisableCategory = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.ENABLE_DISABLE_CATEGORY}/${user}/${id}`);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const addCategory = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.ADD_CATEGORY}/${user}`, payload);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const updateCategory = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.UPDATE_CATEGORY}/${user}`, payload);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getAllSurveys = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.GET_ALL_SURVEYS}/${user}`, payload);
};

/**
 *
 * @param {string} surveyId - Request params
 * @returns Promise
 */
export const deleteSurvey = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.DELETE_SURVEY}/${user}/${id}`);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const addSurvey = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.ADD_SURVEY}/${user}`, payload);
};

/**
 *
 * @param {string} surveyId - Request params
 * @returns Promise
 */
export const getSurveyDetailsById = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.GET_SURVEY_DETAILS}/${user}/${id}`);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const updateSurvey = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.UPDATE_SURVEY}/${user}`, payload);
};
