const urls = {
  SIGN_IN: "validateAuth",
  ADD_SURGEON: "addSurgeon",
  GET_ALL_CATEGORIES: "getAllActiveCategories",
  GET_CATEGORY: "getAllCategories",
  GET_SURVEYBY_CATEGORY: "getSurveyByCategory",
  GET_ALL_SURGEONS: "getAllSurgeons", // It should be POST
  UPDATE_SURGEON: "updateSurgeon",
  DELETE_SURGEON: "deleteSurgeon",
  ENABLE_DISABLE_SURGEON: "enableDisableSurgeon",
  GET_SURGEON_DETAILS: "getSurgeon",

  ADD_CATEGORY: "addCategory",
  // GET_CATEGORIES : "getCategories", // With pagination
  UPDATE_CATEGORY: "updateCategory",
  ENABLE_DISABLE_CATEGORY: "enableDisableCategory",

  ADD_SURVEY: "addSurvey",
  GET_ALL_SURVEYS: "getAllSurveys", // It should be POST
  GET_SURVEY_DETAILS: "getSurvey",
  UPDATE_SURVEY: "updateSurvey",
  DELETE_SURVEY: "deleteSurvey",

  GET_CATEGORY_DETAILS: "getCategory", //NOT Required
};

export default urls;
